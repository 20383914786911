<template>
  <div class="a-dropdown-list">
    <AButton
      ref="trigger"
      variant="link"
      type="button"
      class="a-dropdown-list__trigger"
      :id="uid"
    >
      <slot name="target-content">
        {{ text }}
      </slot>
    </AButton>
    <transition name="fade">
      <div
        :style="cPositionStyle"
        ref="str"
        v-show="cOpen"
        class="a-dropdown-list__target"
      >
        <div ref="strArrow" :style="cPositionStr" :class="strPosition">
          <svg
            width="32"
            height="29"
            viewBox="0 0 32 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7609 1.26086L29.4102 22.0652H2.11156L15.7609 1.26086Z"
              fill="white"
            />
            <path
              d="M2.52344 20.1739L14.9265 1.27398C15.3214 0.672187 16.2037 0.672186 16.5986 1.27398L29.0017 20.1739"
              stroke="#E5E5E5"
            />
          </svg>
        </div>
        <perfect-scrollbar>
          <div class="a-dropdown-list__target__options">
          <slot />
        </div>
        </perfect-scrollbar>
      </div>
    </transition>
  </div>
</template>

<script>
import {inputProps} from "@/components/form/input-props";

export default {
  name: "a-dropdown-list",
  props: {
    ...inputProps,
    text: String,
    open: Boolean,
    options: Array,
    position: {
      type: String,
      default: "center",
      validator: val => ["center", "left", "right"].includes(val)
    }
  },
  data() {
    return {
      openLocal: false,
      el: undefined,
      positionStyle: undefined,
      positionStyleStr: undefined
    };
  },
  computed: {
    cOpen: {
      get() {
        return this.open || this.openLocal;
      },
      set(value) {
        this.openLocal = value;
        this.$emit("update:open", value);
      }
    },
    strPosition() {
      return [
        "a-dropdown-list__target-str",
        {[`position-${this.position}`]: this.position}
      ];
    },
    cPositionStyle() {
      return this.positionStyle || {};
    },
    cPositionStr() {
      return this.positionStyleStr || {};
    }
  },
  mounted() {
    this.el = this.$el;
    this.initPosition();
    document.addEventListener("click", this.toggle);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.toggle);
  },
  methods: {
    toggle(e) {
      if (e.target.id === this.uid) {
        // this.initPosition();
        this.openLocal = !this.openLocal;
      } else {
        this.positionStyle = {};
        this.openLocal = false;
      }
    },
    initPosition() {
      // eslint-disable-next-line no-unused-vars
      let {trigger, str} = this.$refs;
      trigger = trigger.$el;
      let strBound = str.getBoundingClientRect();

      // let triggerCenter = trigger.offsetLeft + trigger.offsetWidth / 2;
      let strH = str.offsetHeight;
      let windowH = window.innerHeight;
      let setToTop =
        strBound.bottom > windowH &&
        strBound.top >= strH &&
        strH + trigger.offsetHeight <= windowH;
      // eslint-disable-next-line no-unused-vars
      let setToCenterScroll = strBound.bottom;
      let setToCenter = strBound.bottom - windowH;
      console.log(strH, strBound.bottom, strBound.top)
      if (setToTop) {
        console.log("setToTop");
        this.positionStyle = {
          top: `${trigger?.offsetHeight - strH}px`
        };
      } else if (setToCenter > 0 && !setToTop) {
        console.log("setToCenter");
        this.positionStyle = {
          // top: `${trigger?.offsetHeight - strH}px`
        };
      } else {
        this.positionStyle = {};
      }
    }
  }
};
</script>

<style scoped lang="scss">
.a-dropdown-list {
  position: relative;

  .ps {
    max-height: 200px;
  }

  &__trigger {
    white-space: nowrap;

    &::v-deep * {
      pointer-events: none;
    }
  }

  &__target {
    background: var(--th-white);
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: -6px 13px 57px rgba(94, 98, 105, 0.15);
    padding: 5px;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;

    &__options {
      white-space: nowrap;
    }

    &-str {
      width: 30px;
      position: absolute;
      top: -20px;
      left: calc(50% - 15px);

      &.position {
        &-center {
          left: 50%;
          transform: translateX(-50%);
        }

        &-left {
          left: calc(100% - 15px);
          margin-left: -15px;
        }

        &-right {
          //left: calc(100% - 14px);
        }
      }
    }
  }
}
</style>
